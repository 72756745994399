import React from 'react'
import { Button, Container, Row, Col, Form } from 'react-bootstrap'
import responsive from '../responsive.svg'
import time from '../time.svg'
import paint from '../paint.svg'
import android from '../android.svg'
import app from '../app.svg'
import speed from '../speed.svg'
import '../styles/Inicio.css'

export const Inicio = () => {
  return (
    <div className='scroll-container h-100'>
      <section className='child'>
        <Splash />
      </section>
      <section className='child scroll-margin'>
        <Web />
      </section>
      <section className='child'>
        <Mobile />
      </section>
      <section className='child' id="contacto">
        <Contacto />
      </section>
    </div>
  )
}

const Splash = () => {
  return (
    <Container className='h-100'>
      <Row className='h-100-vh align-items-center d-flex text-center'>
        <Col>
          <div><h3>Cuentanos tu idea</h3></div>
          <p>Nosotros nos encargamos del resto</p>
          <div><Button className='mt-3' href="#contacto">Solicitar Presupuesto</Button></div>
        </Col>
      </Row>
    </Container>
  )
}
const Web = () => {
  return (
    <Container className='h-100 mb-md-5'>
      <Row className='h-100 align-items-center d-flex text-center'>
        <Col>
          <div className=''><h3>Desarrollo Web</h3></div>
          <p>Levamos a cabo la construcción de tu página en el menor tiempo posible</p>
          <Row className=' justify-content-center'>
            <Col md="4" className='mt-3'>
              <h5>Responsive</h5>
              <img src={responsive} alt=''></img>
              <p>Se ve bien en cualquier tipo de
                 dispositivo</p>
            </Col>
            <Col md="4" className='mt-3'>
              <h5>Rápida</h5>
              <img src={time} style={{ padding: '10px' }} alt=''></img>
              <p>Con tiempos de carga mínimos</p>
            </Col>
            <Col md="4" className='mt-3'>
              <h5>Diseño</h5>
              <img src={paint} style={{ padding: '10px' }} alt=''></img>
              <p>Con un diseño consistente y personalizado</p>
            </Col>
            <Col md="4" className='mt-3'>
              <h5>PWA</h5>
              <img src={android} style={{ padding: '10px' }} alt=''></img>
              <p>Tu web se puede instalar, como si fuese una app nativa</p>
            </Col>
          </Row>

        </Col>
      </Row>
    </Container>
  )
}
const Mobile = () => {
  return (
    <Container className='h-100'>
      <Row className='h-100 align-items-center d-flex text-center'>
        <Col>
          <div><h3>Desarrollo Mobile Nativo</h3></div>
          <p>También desarrollamos aplicaciones Android nativas</p>

          <Row className=' justify-content-center'>
            <Col md="4" className='mt-3'>
              <h5>Publicación</h5>
              <img src={app} style={{ padding: '10px' }} alt=''></img>
              <p>Nos encargamos de la publicación en la Play Store</p>
            </Col>
            <Col md="4" className='mt-3'>
              <h5>Performance</h5>
              <img src={speed} alt=''></img>
              <p>Con la performance que solo una app nativa puede ofrecer</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
const Contacto = () => {
  return (
    <Container className='h-100'>
      <Row className='h-100-vh align-items-center d-flex text-center'>
        <Col>
          <div><h3>Contacto</h3></div>

          <Row className=' justify-content-center'>
            <Col md="8">
              <Form>

                <Form.Group as={Row} controlId="formHorizontalEmail" className='justify-content-center'>
                  <Col xs={10} md={8} lg={6}>
                    <Form.Control type="email" placeholder="Mensaje" />
                  </Col>
                </Form.Group>
              </Form>
            </Col>

          </Row>
          <Row>
            <Col>
              <div><Button className='mt-3 px-5' href="https://api.whatsapp.com/send?phone=542634244670&text=1234">Enviar</Button></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

