import React from 'react'
import './styles/App.css'
import { NavBar } from './components/Navbar'
import { Inicio } from './components/Inicio'

function App () {
  return (
    <div style={{ height: '100vh' }}>
      <NavBar />
      <Inicio />
    </div>
  )
}

export default App
