import React from 'react'
import { Navbar } from 'react-bootstrap'
import penrose from '../penrose.svg'

export const NavBar = () => {
  return (
    <Navbar fixed="top" style={{ background: "white" }}>
      <img
        alt=""
        src={penrose}
        style={{ height: 30, marginRight: 5 }}
        width="30"
        height="30"
        className="d-inline-block align-top"
      />
      <Navbar.Brand href='/'><b>Ascurra</b> Soluciones</Navbar.Brand>
    </Navbar>
  )
}
